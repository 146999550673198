import React from 'react';
import { Routes, Route } from "react-router-dom"
import { Home } from './pages/Home';
import { Navbar } from './components/navbar/Navbar';  
import { Contact } from './pages/Contact'; 
import { MobileNavbar } from './components/navbar/MobileNavbar'; 
import { WhyUs } from './pages/WhyUs';
import { Solutions } from './pages/Solutions';


function App() {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggleOpen = () => setIsOpen(!isOpen)

  return (
    <div className="bg-gradient-to-r from-jig-purple/10 to-jig-purple/10 via-jig-blue/30 font-gilroy">
      {/* <BookDemoModal isOpen={isOpen} toggleOpen={toggleOpen}  /> */}
      <div className='absolute w-full top-0 z-50 hidden md:block'>
        <Navbar toggleOpen={toggleOpen}/>
      </div>
      <div className='absolute w-full top-0 z-50 shadow-md md:hidden'>
        <MobileNavbar />
      </div>
      <Routes>
        <Route path="/" element={ <Home toggleOpen={toggleOpen}/> } /> 
        <Route path="/solutions" element={ <Solutions toggleOpen={toggleOpen} /> } />
        <Route path="/contact" element={ <Contact  toggleOpen={toggleOpen}/> } />
        <Route path="/about" element={ <WhyUs toggleOpen={toggleOpen} /> } />
      </Routes>
    </div>
  );
}

export default App;
