import React, { useState } from "react";
import DatadeerLogo from "../../images/DatadeerLogo.png";
import { BlueButton } from "../default/BlueButton";
import { useNavigate } from "react-router-dom";
import { GreenButton } from "../default/GreenButton"; 
import { useTranslation } from "react-i18next";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

export const Navbar = ({toggleOpen}: any) => {
    const { t, i18n } = useTranslation(); 
    const navigate = useNavigate(); 
    const links = t('links') 

    const [showLanguage, setShowLanguage] = useState(false);
    const toggleLanguage = () => setShowLanguage(!showLanguage);
    const changeLanguage = (lng: string) => {
      i18n.changeLanguage(lng);
    }

    // onClick={()=>{changeLanguage(i18n.language == 'en' ? 'nl' : 'en'); toggleLanguage()}}

    const linkItem = (link: any) => {
      return(
        <div 
          className={`cursor-pointer font-semibold hover:text-black/80 active:text-black/70 animate-200 underline decoration-white hover:decoration-black animate-200`}
          onClick={()=>navigate(link.path)}
        >
          {link.name}
        </div>
        )
    }

    return(
      <div className="items-center m-auto relative z-[50]">
        <div className="m-auto py-4 max-w-6xl px-6 flex items-center">
          <div className="cursor-pointer" onClick={()=>navigate("/")}>
            <img 
              src={DatadeerLogo}
            />
          </div>
          <div className="flex gap-x-4 text-base ml-auto">
            {linkItem(links[0])}
            {linkItem(links[1])}
            {linkItem(links[2])} 
          </div>
          <div className="flex items-center ml-12"> 
            <div>
                <GreenButton text={t('buttonText')} link="/"/>
            </div>
          </div>
          <div className="relative ml-4">
            <div className="cursor-pointer font-bold flex bg-dd-blue text-dd-mint rounded-full z-10 relative w-8 h-8 grid place-content-center shadow-lg" onClick={()=>toggleLanguage()}>
              {i18n.language == 'en' ? 'en' : 'nl'} 
            </div>
            <div className={`cursor-pointer absolute -bottom-7 shadow-lg   w-8 grid place-content-center pb-1 bg-dd-blue/60 text-dd-mint font-bold rounded-b-full pt-4 ${showLanguage ? "" : "hidden"}`} onClick={()=>{changeLanguage(i18n.language == 'en' ? 'nl' : 'en'); toggleLanguage()}}>
              {i18n.language != 'en' ? 'en' : 'nl'}
            </div>
          </div> 
        </div>
      </div>
    )
}