import React, { useState } from 'react';

import HeaderImage from './../images/HeaderImage.png';
import PlaceholderImage from './../images/PlaceholderFiltersImage.png';
import DatadeerIcon from './../images/DatadeerIcon.png';
import HighDetailIcon from './../images/HighDetailIcon.png';
import StructuredSearchIcon from './../images/StructuredSearchIcon.png';
import { FooterComponent } from '../components/default/FooterComponent';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { BlueButton } from '../components/default/BlueButton';

import { useTranslation } from 'react-i18next';

import { NumberItem } from '../components/default/NumberItem';
import { Motion } from '../components/default/Motion';
import { useExternalURLNavigation } from '../hooks/useExternalUrlNavigation';

const Icons = {
	DatadeerIcon: DatadeerIcon,
	HighDetailIcon: HighDetailIcon,
	StructuredSearchIcon: StructuredSearchIcon,
} as any;

export const Home = ({ toggleOpen }: any) => {
	const { t, i18n } = useTranslation();
	const navigateToExternalURLNewTab = useExternalURLNavigation();

	const highlights = t('highlights');
	const tiles = t('productTiles') as any;

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
	};

	const [selectedTile, setSelectedTile] = useState(0);

	return (
		<div className="">
			<div className="grid place-content-center bg-header-background bg-no-repeat bg-cover">
				<div className="flex flex-row max-w-6xl pt-60 pb-32 px-5">
					<div className="flex-1 flex flex-col">
						<div className="text-h1 text-dd-blue text-center md:text-left">
							{t('slogan')}
						</div>
						<div
							className="mt-12 relative mx-5 md:mx-0 cursor-pointer"
							onClick={() =>
								navigateToExternalURLNewTab('https://app.datadeer.nl')
							}
						>
							<input
								onClick={() =>
									navigateToExternalURLNewTab('https://app.datadeer.nl')
								}
								className="rounded-full w-full py-4 bg-dd-mint shadow-lg placeholder-gray-700 pl-12 pr-4 cursor-pointer"
								placeholder={t('searchPlaceholder')}
							/>
							<div className="absolute top-4 left-4">
								<MagnifyingGlassIcon className="w-6 h-6 text-gray-700" />
							</div>
						</div>
					</div>
					<div className="flex-1 hidden md:block">
						<img src={HeaderImage} className="w-[750px]" />
					</div>
				</div>
			</div>

			<div className="min-h-[350px] py-20 md:py-0 bg-dd-mint text-dd-blue flex flex-col items-center justify-text text-justify text-center text-dd-blue">
				<div className="text-3xl px-5 max-w-3xl m-auto text-center">
					<div className="text-h4-black text-dd-blue">{t('orgNeeds')}</div>
					<div className="max-w-3xl m-auto text-h5 text-dd-blue pt-8">
						{t('adhocData')}
					</div>
				</div>
			</div>

			<div className="flex flex-col bg-gradient-to-b from-white via-white to-dd-mint py-16">
				<div className="flex flex-col max-w-6xl m-auto pt-32">
					<div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-40 px-5">
						<NumberItem item={highlights[0]} index={0} />
						<NumberItem item={highlights[1]} index={1} />
						<NumberItem item={highlights[2]} index={2} />
						<NumberItem item={highlights[3]} index={3} />
					</div>
				</div>
				<div className="flex flex-col gap-y-8 md:flex-row gap-x-8 my-8 w-fit m-auto pt-16 pb-4 overflow-hidden">
					<Motion
						direction="left"
						duration={0.3}
						delay={0.0}
						extraClass="rounded-xl bg-[#F6F9FF] w-[350px] shadow-lg"
					>
						<div>
							<img src={PlaceholderImage} />
						</div>
						<div className="text-dd-blue font-bold px-4">
							{t('basicFilters')}
						</div>
						<div className="text-sm pt-4 px-4">{t('basicFiltersDesc')}</div>
						<div className="mt-2 px-4 pb-4">
							<BlueButton text={t('buttonText')} link="/" />
						</div>
					</Motion>
					<Motion
						direction="right"
						duration={0.3}
						delay={0.1}
						extraClass="rounded-xl bg-[#F6F9FF] w-[350px] shadow-lg"
					>
						<div>
							<img src={PlaceholderImage} />
						</div>
						<div className="text-dd-blue font-bold px-4">
							{t('additionalFilters')}
						</div>
						<div className="text-sm pt-4 px-4">
							{t('additionalFiltersDesc')}
						</div>
						<div className="mt-2 px-4 pb-4">
							<BlueButton text={t('buttonText')} link="/" />
						</div>
					</Motion>
				</div>
			</div>

			<div className="bg-dd-blue py-20 text-white px-5 md:px-0">
				<div className="max-w-lg text-center w-fit m-auto">
					<div className="w-fit m-auto">
						<img src={Icons[tiles[selectedTile].icon]} />
					</div>
					<div className="py-8 text-h2 underline text-dd-mint decoration-dd-mint">
						{tiles[selectedTile].title}
					</div>
					<div className="text-h4-medium pb-4">{tiles[selectedTile].sub}</div>
					<div className="text-body">{tiles[selectedTile].text}</div>
				</div>
				<div className="w-fit m-auto flex gap-x-4 flex-row pt-8">
					<div
						className={`animate-200 w-4 h-4 border-2 rounded-full border-white ${
							selectedTile === 0 ? 'bg-white ' : 'cursor-pointer'
						}`}
						onClick={() => setSelectedTile(0)}
					/>
					<div
						className={`animate-200 w-4 h-4 border-2 rounded-full border-white ${
							selectedTile === 1 ? 'bg-white ' : 'cursor-pointer'
						}`}
						onClick={() => setSelectedTile(1)}
					/>
					<div
						className={`animate-200 w-4 h-4 border-2 rounded-full border-white ${
							selectedTile === 2 ? 'bg-white ' : 'cursor-pointer'
						}`}
						onClick={() => setSelectedTile(2)}
					/>
				</div>
			</div>

			<div className="bg-header-background py-16 bg-cover bg-no-repeat text-dd-blue">
				<div className="text-h3 w-fit m-auto">{t('curiousText')}</div>
				<div className="text-h4-medium text-center m-auto">
					{t('tryOutText')}
				</div>
				<div className="w-fit m-auto pt-8">
					<BlueButton text={t('buttonText')} link="/" />
				</div>
			</div>

			<div>
				<FooterComponent />
			</div>
		</div>
	);
};
