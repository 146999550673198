import { useTranslation } from 'react-i18next';

export const useExternalURLNavigation = () => {
	const { i18n } = useTranslation();

	const navigateToExternalURLNewTab = (url: string) => {
		const formattedUrl =
			i18n.language === 'nl' || i18n.language === 'nl-NL'
				? url
				: `${url}/${i18n.language.substring(0, 2)}`;

		window.open(formattedUrl, '_blank');
	};

	return navigateToExternalURLNewTab;
};
