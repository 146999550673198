import { ChevronUpDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { useNavigate } from "react-router";

import { useTranslation } from "react-i18next";

export const FooterComponent = () => {
  const { t, i18n } = useTranslation();
  const links = t('links') as any; 
  const locations = t('contactPage.locations') as any;
  const [showLanguage, setShowLanguage] = useState(false);
  const toggleLanguage = () => setShowLanguage(!showLanguage);
  
  const navigate = useNavigate();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  }

  return(
    <div className="bg-dd-mint py-20 text-dd-blue overflow-hidden">
      <div className="max-w-2xl m-auto flex flex-col gap-y-6 md:flex-row md:text-center px-5 md:px-0">  
        <div className="flex-1 text-left">
          <div className="font-bold">
            {t('menu')}
          </div>
          <div className="pt-1">
            <div className="cursor-pointer hover:underline decoration-jig-blue hover:decoration-jig-purple animate-200" onClick={()=>navigate("/about")}>
              {links[0].name}
            </div>
            <div className="cursor-pointer hover:underline decoration-jig-blue hover:decoration-jig-purple animate-200" onClick={()=>navigate("/solutions")}>
              {links[1].name}
            </div> 
            <div className="cursor-pointer hover:underline decoration-jig-blue hover:decoration-jig-purple animate-200" onClick={()=>navigate("/contact")}>
              {links[2].name}
            </div> 
          </div>
        </div>
        <div className="flex-1 text-left">
          <div className="font-bold">
            {t('contact')}
          </div>
          <div className="pt-1">
            <div className="cursor-pointer hover:underline decoration-jig-blue hover:decoration-jig-purple animate-200">
              <a href="mailto:hello@datadeer.co">{t('email')}</a>
            </div>
            <div className="cursor-pointer hover:underline decoration-jig-blue hover:decoration-jig-purple animate-200">
              <a href="phone:0850606944">{t('phone')}</a>
            </div> 
          </div>
        </div>
        <div className="flex-1 text-left">
          <div className="font-bold">
            {locations[0].name}
          </div>
          <div className="pt-1">
            <div className="decoration-jig-blue hover:decoration-jig-purple animate-200">
              {locations[0].place}  
            </div>
            <div className="decoration-jig-blue hover:decoration-jig-purple animate-200 text-sm">
              <div>
                Barbara Strozzilaan 201 <br/>
                1083 HN Amsterdam
              </div>
            </div> 
          </div>
        </div>
        <div className="flex-1 text-left">
          <div className="font-bold">
            {locations[1].name}
          </div>
          <div className="pt-1">
            <div className="decoration-jig-blue hover:decoration-jig-purple animate-200">
              {locations[1].place}  
            </div>
            <div className="decoration-jig-blue hover:decoration-jig-purple animate-200 text-sm">
              <div> 
                R. Alfredo Allen nº 455-461 <br/> 4200-135 Porto
              </div>
            </div> 
          </div>
        </div>
      </div>
      <div className="flex m-auto max-w-2xl w-full justify-between pt-4 px-5 md:px-0 pb-12 md:pb-4">
        <div className="font-bold">
          Powered by JIGGR
        </div>
        {/* <div className="flex gap-x-4">
          <div>
            Terms and Conditions
          </div>
          <div>
            Privacy Policy
          </div>
        </div> */}
        <div className="relative">
          <div className="cursor-pointer flex" onClick={()=>toggleLanguage()}>
            {i18n.language == 'en' ? 'English' : 'Nederlands'} <span><ChevronUpIcon className="w-4 h-4 ml-1"/></span>
          </div>
          <div className={`cursor-pointer absolute bottom-6 ${showLanguage ? "" : "hidden"}`} onClick={()=>{changeLanguage(i18n.language == 'en' ? 'nl' : 'en'); toggleLanguage()}}>
            {i18n.language != 'en' ? 'English' : 'Nederlands'}
          </div>
        </div> 
      </div>
    </div> 
  )
}