import { useNavigate } from 'react-router-dom';
import { useExternalURLNavigation } from '../../hooks/useExternalUrlNavigation';

type BlueButtonProps = {
	text: string;
	link: string;
	useClick?: boolean;
	click?: any;
};

export const GreenButton = ({
	text,
	link,
	useClick = false,
	click,
}: BlueButtonProps) => {
	const navigate = useNavigate();
	const navigateToExternalURLNewTab = useExternalURLNavigation();

	return (
		<div
			className="green-button"
			onClick={() =>
				useClick
					? click()
					: navigateToExternalURLNewTab('https://app.datadeer.nl')
			}
		>
			{text}
		</div>
	);
};
