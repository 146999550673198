import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import nl from './locales/nl.json';

const resources = {
    en: {
        translation: en
    },
    nl: {
        translation: nl
    }
}


i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        // lng: "nl",
        fallbackLng: 'en',
        // debug: true,
        detection: {
            order: ['queryString', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            cache: ['localStorage']
        },
        interpolation: {
            escapeValue: false,
        },
        returnObjects: true,
        returnArrayObjects: true,
        react: {
            useSuspense: true,
        }
    });

export default i18n;