import React from "react";
import { Motion } from "./Motion";

export const NumberItem = ({item, index}: any) => {

    return(
        <Motion duration={0.3} delay={index/10} extraClass="relative">
            <div className="text-[160px] absolute -top-[140px] font-black text-dd-mint">
                {item.number}
            </div>
            <div className="text-3xl max-w-[230px] leading-[24px] font-black text-dd-blue pl-14 relative z-10">
                {item.title}
            </div>
            <div className="max-w-md pt-3 pl-14 relative z-10">
                {item.text}
            </div>
        </Motion>
    )
}