import { FooterComponent } from "../components/default/FooterComponent";  
import { WordUnderline } from "../components/default/WordUnderline";

import WhyUsImg from './../images/WhyUsImg.png'
import { GreenButton } from "../components/default/GreenButton";

import { useTranslation } from "react-i18next";
import { NumberItem } from "../components/default/NumberItem";

export const WhyUs = ({toggleOpen}: any) => {  
  const { t, i18n } = useTranslation();
  const highlights = t('highlights')
 
  return(
    <div className=""> 
      <div className="grid place-content-center bg-header-background bg-no-repeat bg-cover py-40">
         <WordUnderline text={t('whyUsPage.headerTitle')} />
      </div> 

      <div className="min-h-[350px] py-20 md:py-0 bg-dd-mint text-dd-blue flex flex-col items-center justify-text text-justify text-center text-dd-blue">
          <div className="text-3xl px-5 max-w-3xl m-auto text-center">
            <div className="font-black underline text-2xl py-4 "> 
              {t('whyUsPage.ourDataSection.title')}
            </div> 
            <div className="font-black text-dd-green">
              {t('whyUsPage.ourDataSection.subtitle')} 
            </div> 
            <div className="max-w-3xl m-auto text-xl pt-8 font-bold"> 
              {t('whyUsPage.ourDataSection.description')}
            </div>
          </div>
      </div> 

      <div className="max-w-4xl m-auto pt-20 px-5">
        <div className="font-black text-dd-green text-3xl text-center"> 
          {t('whyUsPage.aboutUsSection.title')}
        </div>
        <div className="max-w-2xl text-center w-fit m-auto text-xl font-bold text-dd-blue pt-4">
          {t('whyUsPage.aboutUsSection.subtitle')}
        </div>
        <div className="flex flex-col md:flex-row py-12 gap-x-8 ">
          <div className="flex-1 flex flex-col gap-y-4">
            <div>
              {t('whyUsPage.aboutUsSection.description1')}
            </div>
            <div>
              {t('whyUsPage.aboutUsSection.description2')} 
            </div>
          </div>
          <div className="flex-1 flex flex-col gap-y-4">
            <div>
              {t('whyUsPage.aboutUsSection.description3')} 
            </div>
            <div>
              {t('whyUsPage.aboutUsSection.description4')} 
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-gradient-to-b from-white via-white to-dd-mint">
        <div className="flex flex-col max-w-6xl m-auto pt-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-40 px-5"> 
            <NumberItem item={highlights[0]} index={0} />
            <NumberItem item={highlights[1]} index={1} />
            <NumberItem item={highlights[2]} index={2} />
            <NumberItem item={highlights[3]} index={3} />
          </div>
        </div> 
        <div className="w-fit m-auto pt-24">
          <img 
            src={WhyUsImg}
          />
        </div>
      </div> 

      <div className="bg-dd-blue text-white py-40">
        <div className="font-black text-3xl w-fit m-auto"> 
          {t('whyUsPage.ctaSection.title')}
        </div>
        <div className="text-2xl w-fit m-auto"> 
          {t('whyUsPage.ctaSection.subtitle')}
        </div>
        <div className="w-fit m-auto pt-8">
          <GreenButton text={t('buttonText')} link="/"/>
        </div>
      </div>

      <div>
        <FooterComponent />
      </div>  
    </div>
  )
}