import React from "react"; 
import { WordUnderline } from "../components/default/WordUnderline"; 
import { FooterComponent } from "../components/default/FooterComponent";  
import { ContactUs } from "../components/default/ContactUs";

import PortoIcon from  "../images/PortoIcon.png";
import NetherlandsIcon from "../images/NetherlandsIcon.png";
import EmailIcon from "../images/EmailIcon.png";
import { Motion } from "../components/default/Motion";
import { useTranslation } from "react-i18next";

import TwitterIcon from './../images/TwitterIcon.png'
import LinkedInIcon from './../images/LinkedInIcon.png'
import FacebookIcon from './../images/FacebookIcon.png'
import InstagramIcon from './../images/InstaIcon.png' 

export const Contact = ({toggleOpen}: any) => {  
  const { t, i18n } = useTranslation();
  const locations = t('contactPage.locations') as any;

  return(
      <div className="">

        <div className="grid place-content-center bg-header-background bg-no-repeat bg-cover py-40">
          <WordUnderline text={t('contactPage.header')} />
        </div>
        <div className="min-h-[350px] py-20 md:py-0 bg-dd-mint text-dd-blue flex flex-col items-center justify-text text-justify text-center text-dd-blue">
          <div className="text-3xl px-5 max-w-3xl m-auto text-center">
            <div className="font-black underline text-2xl py-4">
              {t('contactPage.greeting')}
            </div>
            <div className="font-black text-dd-green">
              {t('contactPage.helpMessage')}
            </div>
            <div className="max-w-3xl m-auto text-xl pt-8 font-bold">
              {t('contactPage.description')}
            </div>
          </div>
        </div> 

        <div className="max-w-3xl py-20 w-full m-auto flex flex-col gap-y-8 md:flex-row text-center justify-between">
          <Motion duration={0.3} delay={0.2} direction={"top"}>
            <img 
              src={NetherlandsIcon}
              className="w-fit h-fit m-auto"
            />
            <div className="font-black text-xl py-3 text-dd-blue">
              {locations[0].name}
            </div>
            <div className="font-bold">
              {locations[0].place}
            </div>
            <div>
              Barbara Strozzilaan 201 <br/>
              1083 HN Amsterdam
            </div>
          </Motion>
          <Motion duration={0.3} delay={0.3}>
            <img 
              src={PortoIcon}
              className="w-fit h-fit m-auto"
            />
            <div className="font-black text-xl py-3 text-dd-blue">
              {locations[1].name}
            </div>
            <div className="font-bold">
              {locations[1].place}
            </div>
            <div> 
              R. Alfredo Allen nº 455-461 <br/> 4200-135 Porto
            </div>
          </Motion>
          <Motion duration={0.3} delay={0.4} extraClass="md:px-0 px-4">
            <img 
              src={EmailIcon}
              className="w-fit h-fit m-auto"
            />
            <div className="font-black text-xl py-3 text-dd-blue">
              {locations[2].name}
            </div>
            <div className="font-bold">
              {locations[2].place}
            </div>
            <div> 
              hello@datadeer.nl <br/> 085 - 06 06 944
            </div>
          </Motion>
        </div>

        {/* <ContactUs showText toggleOpen={toggleOpen}/> */}
        
        {/* <div className="max-w-4xl m-auto w-fit pb-20">
          <div className="text-center text-2xl text-dd-blue md:pt-20 pb-8 font-black">
              {messages.followUs}
          </div>
          <div className="flex flex-row gap-x-6">
            <Motion duration={0.3} delay={0.0}>
                <img
                    className="cursor-pointer hover:scale-110 animate-200" 
                    src={TwitterIcon}
                />
            </Motion>
            <Motion duration={0.3} delay={0.1}>
                <img
                    className="cursor-pointer hover:scale-110 animate-200" 
                    src={FacebookIcon}
                />
            </Motion>
            <Motion duration={0.3} delay={0.2}>
                <img
                    className="cursor-pointer hover:scale-110 animate-200" 
                    src={InstagramIcon}
                />
            </Motion>
            <Motion duration={0.3} delay={0.3}>
                <img
                    className="cursor-pointer hover:scale-110 animate-200" 
                    src={LinkedInIcon}
                />
            </Motion>
          </div>
        </div> */}

        <FooterComponent />  

      </div>
  )
}