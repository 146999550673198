import React, { useState } from "react"; 
import { FooterComponent } from "../components/default/FooterComponent";  
import { WordUnderline } from "../components/default/WordUnderline";
import { GreenButton } from "../components/default/GreenButton";

import SolutionsMegaphone from './../images/SolutionsMegaphone.png'
import SolutionsMail from './../images/SolutionsMail.png'
import SolutionsData from './../images/SolutionsData.png'
import SolutionsSegmentation from './../images/SolutionsSegmentation.png'

import { useTranslation } from "react-i18next";

export const Solutions = ({toggleOpen}: any) => {  
  const { t, i18n } = useTranslation();

  return(
    <div className=""> 
      <div className="grid place-content-center bg-header-background bg-no-repeat bg-cover py-40">
         <WordUnderline text={t('solutionsPage.header')} />
      </div>
      <div className="max-w-4xl m-auto pt-20 md:pt-40 px-5">
        <div className="flex flex-row items-center gap-x-8">
          <div className="flex-1">
            <div className="font-black text-dd-green text-3xl text-left">
              {t('solutionsPage.marketing.title')}
            </div>
            <div className="max-w-2xl text-left w-fit m-auto text-xl font-bold text-dd-blue py-4">
            {t('solutionsPage.marketing.description1')}
            </div>
            <div className="py-4"> 
            {t('solutionsPage.marketing.description2')}
            </div>
            <div>
            {t('solutionsPage.marketing.description3')}
            </div>
          </div>
          <div className="flex-1 hidden md:block">
            <img 
              src={SolutionsMegaphone}
            />
          </div>
        </div>

        <div className="flex flex-row items-center gap-x-8 py-20">
          <div className="flex-1 hidden md:block">
            <img 
              src={SolutionsMail}
            />
          </div>
          <div className="flex-1">
            <div className="font-black text-dd-green text-3xl text-left">
              {t('solutionsPage.directMail.title')}
            </div>
            <div className="max-w-2xl text-left w-fit m-auto text-xl font-bold text-dd-blue py-4">
            {t('solutionsPage.directMail.description1')}
            </div>
            <div className="py-4"> 
            {t('solutionsPage.directMail.description2')}
            </div>
          </div>
        </div>

        <div className="flex flex-row items-center gap-x-8">
          <div className="flex-1">
            <div className="font-black text-dd-green text-3xl text-left">
            {t('solutionsPage.segmentation.title')}
            </div>
            <div className="max-w-2xl text-left w-fit m-auto text-xl font-bold text-dd-blue py-4">
            {t('solutionsPage.segmentation.description1')}
            </div>
            <div className="py-4"> 
            {t('solutionsPage.segmentation.description2')}
            </div>
            <div>
            {t('solutionsPage.segmentation.description3')}
            </div>
          </div>
          <div className="flex-1 hidden md:block">
            <img 
              src={SolutionsSegmentation}
            />
          </div>
        </div>

        <div className="flex flex-row items-center gap-x-8 py-20">
          <div className="flex-1 hidden md:block">
            <img 
              src={SolutionsData}
            />
          </div>
          <div className="flex-1">
            <div className="font-black text-dd-green text-3xl text-left">
            {t('solutionsPage.dataAnalysis.title')}
            </div>
            <div className="max-w-2xl text-left text-xl font-bold text-dd-blue py-4">
            {t('solutionsPage.dataAnalysis.description1')}
            </div>
            <div className="py-4"> 
            {t('solutionsPage.dataAnalysis.description2')}
            </div>
            <div>
            {t('solutionsPage.dataAnalysis.description3')}
            </div>
          </div>
        </div>
      </div> 

      <div className="bg-dd-blue text-white py-40">
        <div className="font-black text-3xl w-fit m-auto">
        {t('solutionsPage.endCta.title')}
        </div>
        <div className="text-2xl w-fit m-auto">
        {t('solutionsPage.endCta.subtitle')}
        </div>
        <div className="w-fit m-auto pt-8">
          <GreenButton text={t('buttonText')} link="/"/>
        </div>
      </div>

      <div>
        <FooterComponent />
      </div>  
    </div>
  )
}